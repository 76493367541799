$clor1: #2e2e2e;
$clor2: #ffffff;

@font-face {
    font-family: "Font1";
    font-style: none;
    font-weight: 100;
    src: url(fonts/Outfit-VariableFont_wght.ttf) format("truetype");
}

a:link {
    text-decoration: none;
    color: $clor1;
}
a:visited {
    text-decoration: none;
    color: $clor1;
}
a:hover {
    text-decoration: none;
    color: $clor1;
}
a:active {
    text-decoration: none;
    color: $clor1;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    padding: 0%;
    margin: 0%;
}

nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: $clor1;
    flex-wrap: wrap;
    position: fixed;
    background-color: $clor2;
    z-index: 1;
    .main {
        padding-left: 5%;
    }
    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 50px;
            height: 50px;
            padding: 0%;
        }
    }
    h2 {
        padding-left: 2%;
        font-family: "Font1";
        font-size: 2em;
        color: $clor1;
    }
    ul {
        width: 40%;
        display: flex;
        justify-content: space-evenly;
        li {
            list-style: none;
            font-size: 1.3em;
            font-family: "Font1";
            padding: 0 10px 0 10px;
            color: $clor1;
        }
    }
}
#home {
    background-image: url("./img/graph-2588993_1920.jpg");
    width: 100%;
    height: 100vh;
    padding: 0%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100%;
    div {
        position: absolute;
        left: 5%;
        top: 20%;
    }
    h1 {
        font-size: 5em;
        font-family: "Font1";
        color: $clor2;
    }
    h3 {
        font-size: 1.5em;
        font-family: "Font1";
        color: $clor2;
    }
}
#Aboutus {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $clor2;
    h2 {
        margin-top: 5%;
        font-size: 3em;
        font-family: "Font1";
        color: $clor1;
    }
    div {
        display: flex;
        padding: 0 10% 0 10%;
        align-items: center;
        p {
            font-size: 1.5em;
            font-family: "Font1";
            color: $clor1;
        }
        img {
            width: 40rem;
            height: 40rem;
            padding-left: 10%;
        }
    }
}
#ourfeatures {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    h2 {
        margin-top: 5%;
        font-size: 3em;
        font-family: "Font1";
        color: $clor1;
    }
    .maina {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 100%;
        .feature {
            width: 40%;
            div {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
            }
        }
        h3 {
            margin-top: 5%;
            font-size: 2em;
            font-family: "Font1";
            color: $clor1;
        }
        img {
            height: 100px;
            width: auto;
            margin-right: 5%;
        }
        p {
            font-size: 1.5em;
            font-family: "Font1";
            color: $clor1;
        }
    }
}
input:focus,
textarea:focus {
    border: none;
    outline: none;
}
#contactus {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    height: 100vh;
    h2 {
        margin-top: 5%;
        font-size: 3em;
        font-family: "Font1";
        color: $clor1;
    }
    .mainaa {
        display: flex;
        width: 100%;
        height: 80%;
        justify-content: space-evenly;
        align-items: center;
        form {
            display: flex;
            width: 40%;
            flex-direction: column;
            height: 60vh;
            justify-content: space-evenly;
            label {
                font-size: 1.2em;
                color: $clor1;
                font-family: "Font1";
                margin: 20px 0;
            }
            input {
                border: none;
                border-bottom: $clor1 1px solid;
                height: 3rem;
                font-size: 1.3em;
                color: $clor1;
                font-family: "Font1";
            }
            textarea {
                border: none;
                border-bottom: $clor1 1px solid;
                height: 10rem;
                font-size: 1.5em;
                color: $clor1;
                font-family: "Font1";
            }
            button {
                border: none;
                outline: none;
                padding: 15px;
                font-family: "Font1";
                font-size: 1.5em;
                margin: 20px;
                width: fit-content;
                border-radius: 10%;
            }
        }
        .social {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30%;
            a {
                margin: 10%;
                font-size: 1.3em;
                color: $clor1;
                font-family: "Font1";
                display: flex;
                align-items: center;
                img {
                    width: 30px;
                    height: auto;
                    margin-right: 20px;
                }
            }
        }
    }
}
button:hover {
    background-color: $clor1;
    color: $clor2;
}
footer {
    background-color: $clor1;
    margin: 0%;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        padding: 0%;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        li {
            list-style: none;
            font-size: 1.3em;
            font-family: "Font1";
            color: $clor2;
            a:link {
                text-decoration: none;
                color: $clor2;
            }
            a:visited {
                text-decoration: none;
                color: $clor2;
            }
            a:hover {
                text-decoration: none;
                color: $clor2;
            }
            a:active {
                text-decoration: none;
                color: $clor2;
            }
        }
    }
}
@media (max-width: 1200px) {
    #home {
        background-size: auto;
        height: 70vh;
        div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-self: center;
            flex-direction: column;
        }
    }
    #Aboutus {
        height: auto;
        div {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 90%;
                height: auto;
                padding: 0%;
            }
        }
    }
}
@media (max-width: 975px) {
    nav {
        justify-content: baseline;
        align-items: center;
        ul {
            width: 50%;
            padding: 0%;
            justify-content: space-evenly;
            padding-left: 0%;
            li {
                padding: 0px;
            }
        }
        .main {
            padding-left: 0%;
        }
        .logo {
            padding: 0%;
        }
    }
}
@media (max-width: 720px) {
    nav {
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        padding: 0%;
        width: 100vw;
        overflow-x: hidden;
        ul {
            width: 100vw;
            padding: 0%;
            justify-content: space-evenly;
            padding-left: 0%;
            li {
                padding: 0px;
            }
        }
        .main {
            padding-left: 0%;
        }
        .logo {
            padding: 0%;
        }
    }
    #home {
        background-size: auto;
        width: 100%;
        height: 100vh;
        div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-self: center;
            flex-direction: column;
        }
    }
}
@media (max-width: 540px) {
    nav {
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        ul {
            width: 100%;
            padding: 0%;
            justify-content: space-evenly;
            padding-left: 0%;
            li {
                padding: 0px;
            }
        }
        .main {
            padding-left: 0%;
        }
        .logo {
            padding: 0%;
        }
    }
    #home {
        background-size: auto;
        div {
            position: absolute;
            display: flex;
            justify-content: center;
            align-self: center;
            flex-direction: column;
        }
    }
    #Aboutus {
        margin-top: 10%;
        height: auto;
        div {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
                width: 90%;
                height: auto;
                padding: 0%;
            }
        }
    }
    #ourfeatures {
        .maina {
            width: 100%;
            justify-content: center;
            .feature {
                width: 90%;
            }
        }
    }
    #contactus {
        height: auto;
        .mainaa {
            width: 100%;
            height: auto;
            flex-direction: column;
            form {
                width: 90%;
                margin: 10%;
            }
            .social {
                width: 80%;
            }
        }
    }
    footer {
        padding: 10% 0 10% 0;
        align-items: center;
        justify-content: center;
        ul{
            margin: 0%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            li{
                padding: 10px 0 10px 0;
            }

        }
    }
    .tap{
        height: 200vh;
        padding:  20vh 5px 10vh 5px;
    }
    .tad{
        height: 240vh;
        padding:  20vh 5px 10vh 5px;
    }
}
.tap{
    height: 130vh;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        margin: 60vh 0 55vh 0;
        font-family: "Font1";
        font-size: 1.5em;
    }
}
.tad{
    height: 170vh;
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        margin: 200vh 0 195vh 0;
        font-family: "Font1";
        font-size: 1.5em;
    }
}
#pricing{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        margin-top: 5%;
        font-size: 3em;
        font-family: "Font1";
        color: $clor1;
    }
    .flex{
        display: flex;
        justify-content: space-evenly;
        width: 100vw;
        flex-wrap: wrap;
        div{
            font-family: "Font1";
            font-size: 1.5em;
            width: 20em;
            margin: 5px;
            img{
                width: 100%;
                height: 250px;
            }
            ul li {
                list-style: none;
                position: relative;
                padding-left: 20px;
                padding-top: 5px;  
            }
            ul li::before {
                content: "✅"; 
                position: absolute;
                left: 0;
                color: $clor1; 
                font-size: 1.2em;
            }
            border-radius: 10px;
        }
        div:hover{
            box-shadow: rgba(46, 46, 46, 0.4) 5px 5px, rgba(46, 46, 46, 0.3) 10px 10px, rgba(46, 46, 46, 0.2) 15px 15px, rgba(46, 46, 46, 0.1) 20px 20px, rgba(46, 46, 46, 0.05) 25px 25px;
            padding: 0.2;
            transition: all 0.3s ease-in-out;
        }
    }
}