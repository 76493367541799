.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 40px 30px;
  position: fixed; /* Changed to fixed */
  top: 0; /* Added top: 0 */
  left: 0; /* Added left: 0 */
  width: 100%; /* Added width: 100% */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
  z-index: 1000; /* Ensure it stays on top */
  /* Highlighted text */
  /* Fix the logo path */
}
.navbar .logo-container {
  display: flex;
  align-items: center;
}
.navbar .logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white; /* Added color to the logo text */
}
.navbar .logo {
  height: 40px;
  margin-right: 10px;
}
.navbar .logo-container .highlighted {
  background: linear-gradient(to right, #ff9a9e, #fecfef); /* A modern gradient color */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold; /* Make it stand out */
  font-size: 1.2em;
}
.navbar .logo-container img {
  content: url("../../img/logo.png"); /* Corrected path */
}
.navbar .nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0; /* Added to remove default margin */
  padding: 0; /* Added to remove default padding */
}
.navbar .nav-links li {
  display: inline;
}
.navbar .nav-links a {
  color: white;
  font-size: 18px;
  text-decoration: none;
  padding: 5px;
  transition: color 0.3s ease;
}
.navbar .nav-links a:hover {
  color: #61dafb; /* Change color on hover */
}
.navbar .hamburger-menu {
  display: none;
  background: none;
  border: none;
  font-size: 30px;
  color: white;
  cursor: pointer;
  padding: 10px;
}
@media (max-width: 700px) {
  .navbar .navbar {
    flex-direction: row;
    align-items: stretch; /* Changed to stretch */
    position: fixed; /* Changed to fixed */
    top: 0; /* Added top: 0 */
    left: 0; /* Added left: 0 */
  }
  .navbar .logo-container {
    width: 100%; /* Added width 100%*/
    text-align: center; /* Center the logo on mobile */
  }
  .navbar .nav-links {
    display: none;
    flex-direction: column;
    position: static; /* Changed to static */
    top: auto; /* Changed to auto */
    left: auto; /* Changed to auto */
    width: 100%;
    background-color: #333;
    text-align: center;
    padding: 10px 0;
    margin-top: 0; /* Removed margin */
  }
  .navbar .nav-links.open {
    display: flex;
  }
  .navbar .nav-links.closed {
    display: none;
  }
  .navbar .hamburger-menu {
    display: block;
    position: absolute;
    top: 30px;
    right: 10px;
  }
  .navbar .logo-container span {
    color: white;
    font-size: 22px;
  }
}
@media (min-width: 701px) {
  .navbar .nav-links {
    display: flex; /* added this to view on desktop */
    width: 50%; /* added this to view on desktop */
  }
}/*# sourceMappingURL=navbar.css.map */